import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, Polyline, useJsApiLoader } from "@react-google-maps/api";
import {SOCKET_URL} from "../../api";
import io from "socket.io-client";

const Tracker = (props) => {

    const deviceId = props.match.params.id;

    const [map, setMap] = useState(null);
    const [path, setPath] = useState([]);
    const [markerPosition, setMarkerPosition] = useState(null);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
    });

    useEffect(() => {
        if (!isLoaded) return;

        const fetchInitialLocation = async () => {
            try {
                const response = await fetch(`/school/location/${deviceId}`);
                const data = await response.json();
                const position = { lat: data.latitude, lng: data.longitude };

                setMarkerPosition(position);
                setPath((prevPath) => [...prevPath, position]);
            } catch (error) {
                console.error("Failed to fetch initial location:", error);
            }
        };

        fetchInitialLocation();

        const socket = io(SOCKET_URL, {
            transports: ["websocket", "polling"]
        });

        let lastPosition = null;

        const animateMarkerMove = (oldPosition, newPosition) => {
            const deltaLat = (newPosition.lat - oldPosition.lat) / 100;
            const deltaLng = (newPosition.lng - oldPosition.lng) / 100;

            let i = 0;
            const interval = setInterval(() => {
                i++;
                const lat = oldPosition.lat + deltaLat * i;
                const lng = oldPosition.lng + deltaLng * i;
                setMarkerPosition({ lat, lng });

                if (i >= 100) {
                    clearInterval(interval);
                }
            }, 10);
        };

        socket.on("connect", () => {
            console.log("Connected to server:", socket.id);
        });

        socket.on(`location-update-${deviceId}`, (data) => {
            const newPosition = { lat: data.latitude, lng: data.longitude };

            if (lastPosition) {
                animateMarkerMove(lastPosition, newPosition);
            } else {
                setMarkerPosition(newPosition);
            }

            setPath((prevPath) => [...prevPath, newPosition]);
            lastPosition = newPosition;
        });

        return () => {
            socket.disconnect();
        };
    }, [isLoaded, deviceId]);

    const mapContainerStyle = {
        height: "100vh",
        width: "100%",
    };

    const options = {
        styles: [
            {
                featureType: "poi",
                elementType: "all",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "landscape.man_made",
                elementType: "geometry",
                stylers: [{ visibility: "off" }],
            },
        ],
        disableDefaultUI: true,
    };

    if (!isLoaded) return <div>Loading...</div>;

    return (
        <div style={{ position: "relative", height: "100vh", width: "100%" }}>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={markerPosition || { lat: 0, lng: 0 }}
                zoom={15}
                options={options}
                onLoad={(map) => setMap(map)}
            >
                {markerPosition && (
                    <Marker
                        position={markerPosition}
                        icon={{
                            origin: new window.google.maps.Point(0, 0),
                            anchor: new window.google.maps.Point(25, 50),
                        }}
                    />
                )}
                <Polyline
                    path={path}
                    options={{
                        strokeColor: "#FF0000",
                        strokeOpacity: 1.0,
                        strokeWeight: 8,
                    }}
                />
            </GoogleMap>
            <img
                id="_logo"
                src="/assets/images/logo.png"
                alt="logo"
                style={{
                    padding: "6px",
                    backgroundColor: "white",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    height: "50px",
                    borderRadius: "0 30px 0 0",
                }}
            />
        </div>
    );
};

export default Tracker;
