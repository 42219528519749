import React from 'react';
import { Route } from 'react-router-dom';
import Header from "../layout/Header";

const PublicRoute = ({component: Component, ...rest}) => {

    return(
        <Route
            {...rest}
            render={props => {
                const isTrackRoute = /^\/school\/track\/[^/]+$/.test(rest.location.pathname);

                return (
                    <div id="wrapper" className="horizontal">
                        {!isTrackRoute && <Header />}
                        <Component {...props} />
                    </div>
                );
            }}
        />
    )
};

export default PublicRoute;
