import React, { useEffect } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/auth/Login";
import { Provider } from "react-redux";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import store from "./stores";
import Register from "./pages/auth/Register";
import Explore from "./pages/public/Explore";
import PublicRoute from "./components/common/PublicRoute";
import Courses from "./pages/public/Courses";
import Course from "./pages/public/Course";
import Book from "./pages/public/Book";
import CourseList from "./pages/public/CourseList";
import Category from "./pages/public/Category";
import PageError from "./components/layout/PageError";
import Private from "./components/routes/Private";
import BookDesc from "./pages/public/Book";
import Product from "./pages/public/Product";
import Products from "./pages/public/Products";
import Books from "./pages/public/Books";
import Academia from "./pages/public/Academia";
import Tracker from "./pages/public/Tracker";
import PrivacyPolicy from "./pages/public/PrivacyPolicy";
import setAuthToken from "./utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { SET_AUTH_USER } from "./stores/auth";

//Check for token
if (localStorage.jwtToken) {
  //Set auth token header auth
  setAuthToken(localStorage.jwtToken);

  //Decode token and get user info
  const decoded = jwt_decode(localStorage.jwtToken);

  //Set user and isAuthenticated
  store.dispatch(SET_AUTH_USER({ user: decoded, isAuthenticated: true }));

  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    // store.dispatch(logoutUser());

    //Clear current profile
    window.location.href = "/";
  }
}

const App = () => {
  // useEffect(() => {
  //     const firebaseConfig = {
  //         apiKey: "Put your ID",
  //         authDomain: "",
  //         projectId: "",
  //         storageBucket: "",
  //         messagingSenderId: "",
  //         appId: "",
  //         measurementId: ""
  //     };
  // useEffect(() => {
  //     const firebaseConfig = {
  //         apiKey: "Put your ID",
  //         authDomain: "",
  //         projectId: "",
  //         storageBucket: "",
  //         messagingSenderId: "",
  //         appId: "",
  //         measurementId: ""
  //     };

  //     // Initialize Firebase
  //     const app = initializeApp(firebaseConfig);
  //     getAnalytics(app);
  // }, []);
  //     // Initialize Firebase
  //     const app = initializeApp(firebaseConfig);
  //     getAnalytics(app);
  // }, []);

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <PublicRoute exact path="/" component={Explore} />
            <PublicRoute exact path="/courses" component={Courses} />
            <PublicRoute exact path="/products" component={Products} />
            <PublicRoute exact path="/academia" component={Academia} />
            <PublicRoute exact path="/books" component={Books} />
            <PublicRoute exact path="/course/:id" component={Course} />
            <PublicRoute exact path="/book/:id" component={Book} />
            <PublicRoute exact path="/product/:id" component={Product} />
            <PublicRoute exact path="/course-list/:id" component={CourseList} />
            <PublicRoute exact path="/category" component={Category} />
            <PublicRoute exact path="/book" component={BookDesc} />
            <PublicRoute exact path="/school/track/:id" component={Tracker} />
            <PublicRoute exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Private />
            <Route component={PageError} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
};

export default App;
